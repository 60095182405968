<template>
  <div
    class="c-app flex-row align-items-center"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" class="p-4 text-center">
          <img src="/img/menute.be/logo.png" class="img-drop-shadow" />
        </CCol>

        <CCol md="8" class="mt-4">
          <alert-section
            :successAlertMessage="successAlertMessage"
            :dismissSecs="dismissSecs"
            :dismissSuccessAlert="dismissSuccessAlert"
            :errorAlertMessage="errorAlertMessage"
            :showErrorAlert="showErrorAlert"
          />
        </CCol>

        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <!-- Login -->
                <CForm v-if="!isLoggedIn" @submit.prevent="login">
                  <h2>Login</h2>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="E-mail"
                    autocomplete="username email"
                    :lazy="false"
                    :value.sync="$v.form.email.$model"
                    :isValid="checkIfValid('form', 'email')"
                  >
                    <template #prepend-content><CIcon name="cil-at" /></template>
                  </CInput>

                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    :lazy="false"
                    :value.sync="$v.form.password.$model"
                    :isValid="checkIfValid('form', 'password')"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                  </CInput>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton type="submit" class="px-4 bg-menute" :disabled="submitted" @click="login()">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CLink style="color: #d60b52" class="d-lg-none" href="https://www.menute.be/#nav/#login/#register" target="_blank" >
                        Register Now!
                      </CLink>
                    </CCol>
                  </CRow>
                </CForm>

                <!-- Verify Otp -->
                <CForm v-else @submit.prevent="verifyOtp">
                  <h2>2-Step Verification</h2>
                  <p class="text-muted">A text message with a 6-digit verification code was just sent to {{ masked2FANumber }}</p>
                  <CInput
                    placeholder="Enter the code"
                    :lazy="false"
                    :value.sync="$v.otpForm.otp.$model"
                    :isValid="checkIfValid('otpForm', 'otp')"
                    type="number"
                  >
                    <template #prepend-content><CIcon name="cil-asterisk" /></template>
                  </CInput>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton type="submit" class="px-4" color="warning" :disabled="submitted" @click="verifyOtp()">Verify</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Resend code</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>

            <!-- Sign up -->
            <CCard class="text-center py-5 d-md-down-none" body-wrapper  :class="`bg-${!isLoggedIn ? 'menute' : 'warning'}`">
              <CCardBody v-if="!isLoggedIn">
                <h2>Sign up</h2>
                <p>Order food online via Menute.be, the fastest growing order portal in Belgium.</p>
                <CLink class="btn btn-outline-light btn-lg" href="https://www.menute.be/#nav/#login/#register" target="_blank" >Register Now!</CLink>
              </CCardBody>
              <CCardBody v-else>
                <h5>Back to login</h5>
                <CLink @click="() => isLoggedIn = !isLoggedIn" class="btn btn-outline btn-lg"><CIcon name="cil-arrow-left" size="xl" /></CLink>
              </CCardBody>
            </CCard>
          </CCardGroup>

          <CElementCover :opacity="0.4" v-show="submitted"/>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "Login",

  data: function () {
    return {
      form: {},
      otpForm: {},
      isLoggedIn: false,
      loggedInUser: null,
      submitted: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 2,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    };
  },

  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
    otpForm: {
      otp: { required },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    masked2FANumber() {
      const originalNumber = this.loggedInUser?.tfamobile;

      if (!originalNumber) return "";

      return originalNumber.length <= 4
        ? originalNumber
        : "*".repeat(originalNumber.length - 4) + originalNumber.slice(-4);
    }
  },

  created() {
    this.isUserLoggedIn && this.loggedIn();
  },

  mounted() {
    this.getForms();
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest", "verifyOTP"]),

    async login() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        return;
      }

      this.submitted = true;

      await this.sendLoginRequest(this.form)
        .then((response) => {

          if (response.data['2fa'] === true) {
            this.loggedInUser = response.data.data.user;
            this.isLoggedIn = true;
          } else {
            this.loggedIn();
          }
        })
        .catch((error) => {
          var errorMessage = {};

          errorMessage["Error"] = !error.response
            ? error
            : error.response.data.message;

          this.errorAlertMessage = errorMessage;
          this.showErrorAlert = true;
          this.form.password = "";
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    loggedIn() {
      if (this.$route.query.returnUrl) {
        // Redirect to requested page after login
        this.$router.replace(this.$route.query.returnUrl);
      } else {
        this.$router.push({ name: "Home" }).catch(() => { });
      }
    },

    async verifyOtp() {
      if (this.$v.otpForm.$invalid) {
        this.$v.otpForm.$touch();
        return;
      }

      this.submitted = true;

      await this.verifyOTP({ 'user-id': this.loggedInUser.id, otp: this.otpForm.otp })
        .then(this.loggedIn)
        .catch((error) => {
          var errorMessage = {};

          errorMessage["Error"] = !error.response
            ? error
            : error.response.data.message;

          this.errorAlertMessage = errorMessage;
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    checkIfValid(form, fieldName) {
      const field = this.$v[form][fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch();
    },

    getForms() {
      this.form = { email: "", password: "" };
      this.otpForm = { otp: "" };
    },
  },
};
</script>

<style scoped>
.img-drop-shadow {
  /* filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5)); */
  filter: drop-shadow(1px 1px 1px #9b0a0a);
}
.bg-menute {
  background-color: #9b0a0a !important;
  color: #fff !important;
}
.btn-link {
  color: #9b0a0a !important;
}
</style>
